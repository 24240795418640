<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          xs="12"
          md="4"
        >
          <b-form-group label="Başlangıç Tarihi">
            <b-form-datepicker
              v-model="sdate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col
          xs="12"
          md="4"
        >
          <b-form-group label="Bitiş Tarihi">
            <b-form-datepicker
              v-model="edate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col
          xs="12"
          md="4"
        >
          <b-form-group label="Marka">
            <v-select
              id="id_com_model"
              v-model="id_com_brand"
              :options="brands"
              :reduce="brand => brand.id"
              label="title"
              placeholder="Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            variant="primary"
            :disabled="!sdate || !edate"
            @click="getI2SurveyReport"
          >
            <FeatherIcon icon="BarChartIcon" />
            <span class="align-middle">Raporu Görüntüle</span>
          </b-button>
          <b-button
            v-if="SaleSurveyReport.users.length > 0"
            variant="success"
            class="ml-1"
            :href="downloadURL"
          >
            <FeatherIcon icon="DownloadIcon" />
            <span class="align-middle">Excel İndir</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-row v-if="reportLoading">
      <b-col cols="12">
        <Loading />
      </b-col>
    </b-row>
    <b-card
      v-if="SaleSurveyReport.users.length > 0"
      title="Rapor Özeti"
    >
      <b-row>
        <b-col
          xs="12"
          md="8"
        >
          <b-table-simple>
            <b-thead>
              <b-tr>
                <b-th class="align-middle">
                  Danışman
                </b-th>
                <b-th class="align-middle text-center">
                  Satış
                </b-th>
                <b-th class="align-middle text-center">
                  Anket
                </b-th>
                <b-th class="align-middle text-center">
                  Puan Ortalaması
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(user,key) in SaleSurveyReport.users"
                :key="key"
              >
                <b-td class="align-middle">
                  {{ user.username }}
                  <div>
                    <small class="text-warning">{{ user.user_brand }}</small>
                  </div>
                </b-td>
                <b-td class="align-middle text-center">
                  {{ user.sales }}
                </b-td>
                <b-td class="align-middle text-center">
                  {{ user.surveys }}
                </b-td>
                <b-td class="width-100 text-center">
                  {{ user.score }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col
          xs="12"
          md="4"
        >
          <b-row>
            <b-col
              md="12"
              sm="12"
              class="mb-2"
            >
              <item-statistics
                title="Satış"
                :value="SaleSurveyReport.totals.sales"
                color="light-success"
                icon="TrendingUpIcon"
              />
            </b-col>
            <b-col
              md="12"
              sm="12"
              class="mb-2"
            >
              <item-statistics
                title="Anket"
                :value="SaleSurveyReport.totals.surveys"
                color="light-info"
                icon="PhoneOutgoingIcon"
              />
            </b-col>
            <b-col
              md="12"
              sm="12"
              class="mb-2"
            >
              <item-statistics
                title="Galeri - Diğer"
                :value="SaleSurveyReport.totals.others"
                color="light-danger"
                icon="PhoneOffIcon"
              />
            </b-col>
            <b-col
              md="12"
              sm="12"
              class="mb-2"
            >
              <item-statistics
                title="Memnuniyet Oranı"
                :value="SaleSurveyReport.totals.score + ' / 10'"
                color="light-warning"
                icon="PieChartIcon"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="SaleSurveyReport.users.length > 0"
      title="Durum Özeti"
    >
      <b-row>
        <b-col
          v-for="(status,key) in SaleSurveyReport.statuses"
          :key="key"
          md="4"
          sm="12"
          class="mb-2"
        >
          <item-statistics
            :title="status.interview_status"
            :value="status.count"
            color="light-info"
            icon="BookmarkIcon"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTh,
  BThead,
  BTr,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import vSelect from 'vue-select'
import ItemStatistics from '@/views/Reports/Crm/components/ItemStatistics.vue'
// eslint-disable-next-line import/extensions
import Loading from '@/layouts/components/Loading'

export default {
  name: 'Surveys',
  components: {
    vSelect,
    BCard,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    ItemStatistics,
    Loading,
  },
  data() {
    return {
      sdate: null,
      edate: null,
      id_com_brand: null,
      reportLoading: null,
    }
  },
  computed: {
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    downloadURL() {
      const urlParams = [
        `&sdate=${this.sdate}`,
        `edate=${this.edate}`,
        `id_com_brand=${this.id_com_brand}`,
      ].join('&')
      const url = `${this.$store.state.app.baseURL}/exports/reports/crm/surveys/inallar?token=${localStorage.getItem('downloadToken')}${urlParams}`
      return url
    },
    SaleSurveyReport() {
      const data = this.$store.getters['saleSurveysReport/getSaleSurveyReport']
      if (data) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.reportLoading = false
      }
      return data
    },
  },
  created() {
    this.$store.dispatch('saleSurveysReport/resetSaleSurveyReport')
    this.getBrands()
  },
  methods: {
    getI2SurveyReport() {
      this.reportLoading = true
      this.$store.dispatch('saleSurveysReport/SaleSurveyReport', {
        sdate: this.sdate,
        edate: this.edate,
        id_com_brand: this.id_com_brand,
      })
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
